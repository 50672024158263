<template>
	<b-form-select v-model="inputValue">
		<template v-for="(arr, index) in options" :value="index">
			<b-form-select-option :value="index" :key="index">
				<template v-if="arr.name !== ''">
					{{ arr.name }}
				</template>
				<template v-else> Aftale {{ index + 1 }} </template>
			</b-form-select-option>
		</template>
	</b-form-select>
</template>

<script>
export default {
	name: 'AgreementDropdown',
	props: {
		options: Array,
		value: Number,
	},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<style></style>
