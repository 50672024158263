<template>
	<section class="row mb-4 lb">
		<div class="col-12">
			<b>
				<slot name="title"></slot>
			</b>
		</div>
		<div class="col-md-4 col-12">
			<slot name="taxCode"></slot>
			<slot name="ageGroup"></slot>
			<slot name="supplierComment"></slot>
			<slot name="comment"></slot>
			<slot name="employeeComment"></slot>
		</div>
		<div class="col-md-4 col-12">
			<slot name="comp"> </slot>
		</div>
	</section>
</template>

<script>
export default {
	name: 'InsuranceField',
	props: {
		tax: {
			type: Array,
		},
		text: {
			type: Boolean,
		},
	},
	data() {
		return {}
	},
	methods: {
		update(data) {
			this.$emit('input', data)
		},
	},
}
</script>

<style lang="scss"></style>
