<template>
  <section>
    <b-form-select
      v-model="inputValue"
      :disabled="disabled"
      :options="options"
      class="form-control mb-2"
      :placeholder="placeholder"
      :required="required"
      :state="isRequiredAndFilled"
    >
    </b-form-select>
  </section>
</template>

<script>
import requiredFieldMixin from "../../../mixins/requiredFieldMixin";

export default {
  name: "InputDropdown",
  props: ["disabled", "value", "placeholder", "required"],
  mixins: [requiredFieldMixin],
  data() {
    return {
      options: [
        { value: "percentage", text: "% af årsløn" },
        { value: "currency", text: "DKK" },
      ],
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>