<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'EmployeePolicy', 'Pension')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>

		<b-form @submit.prevent="onSubmit">
			<div class="row">
				<div class="col-auto p-0">
					<h3>
						<b>{{ $t('global.title.agreement') }}</b>
					</h3>
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-lg-4 col-12" v-if="pensions.length == 0">
					<h3>Der er ikke oprettet en pensionsaftale til medarbejderen endnu / Gruppen har ikke en pensionsaftale tilknyttet endnu</h3>
				</div>
				<div class="col-lg-4 col-12" v-if="pensions.length > 0">
					<AgreementDropdown v-model="agreementIndex" :options="pensions" disabled="true" />
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<hr />
				</div>
			</div>

			<div class="row" v-if="pensions.length > 0">
				<EmployeePension
					v-model="employeePolicy.pensions[agreementIndex]"
					:standardPension="standardPension"
					v-bind:key="agreementIndex"
					:pensionIndex="agreementIndex"
					:disabled="disabled"
					:suppliers="suppliers"
				/>
			</div>

			<div class="row mb-5 mt-5">
				<div class="col-3">
					<BaseActionButton class="w-100 btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>

<script>
import EmployeePension from '@/modules/general/employee/components/EmployeePension.vue'
import Swal from 'sweetalert2'
import AgreementDropdown from '@/modules/global/atomComponents/AgreementDropdown'

export default {
	name: 'CompanyEmployeePension',
	components: {
		EmployeePension: EmployeePension,
		AgreementDropdown,
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
		if (this.employeePolicy.employeeCondition.group === undefined) {
			this.fireWarningMessage()
		}
	},
	data() {
		return {
			index: 0,
			clicked: false,
			disabled: true,
		}
	},
	methods: {
		changeAgreementIndex() {
			this.agreementIndex = this.pensions.length - 1
		},
		setEditable() {
			this.disabled = !this.disabled
		},
		async onSubmit() {
			this.clicked = true
			var policyId = this.employeePolicy.id
			await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
				id: policyId,
				data: this.employeePolicy,
				companyId: this.companyId,
				employeeId: this.employeeId,
			})
			this.toast('Success', 'En medarbejder nu opdateret', true)
			this.clicked = false
		},
		fireWarningMessage() {
			Swal.fire({
				title: 'Gruppe er ikke valgt',
				text: 'Gruppe for medarbejderen er ikke valgt!',
				icon: 'warning',
			}).then((result) => {
				this.$router.push({ name: 'CompanyEmployeeCondition' })
			})
		},
	},
	computed: {
		agreementIndex() {
			var pensionId = this.$route.params.pensionId
			var index = this.pensions.findIndex((pension) => {
				return pension.pensionId === pensionId
			})
			return index
		},
		company() {
			return this.$store.getters['employeeVuex/company']
		},
		groups() {
			return this.$store.getters['employeeVuex/company'].groups
		},
		currentPension() {
			return this.employeePolicy?.pensions[this.agreementIndex]
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		pensions() {
			return this.employeePolicy?.pensions.map((item) => {
				var companyPension = this.companyPensions.find((companyPension) => {
					return companyPension.id === item.pensionId
				})
				return { ...item, name: companyPension.pension.name }
			})
		},
		standardPension() {
			var standardPension = this.company?.pensions.find((companyPension) => {
				return companyPension.id === this.currentPension.pensionId
			})
			return standardPension?.pension
		},
		companyPensions() {
			return this.company?.pensions
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		suppliers() {
			var suppliers = this.$store.getters['supplier/suppliers']
			return suppliers
		},
	},
}
</script>

<style></style>
