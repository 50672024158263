<template>
	<section class="col-12">
		<div class="row">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.supplier') }}</b>
				</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-12">
				<supplier v-model="standardPension.supplierId" :suppliers="suppliers" :disabled="true" />
			</div>
		</div>

		<template v-if="standardPension.supplierId !== ''">
			<div class="row">
				<div class="col-12">
					<hr />
				</div>
			</div>
			<div class="row">
				<div class="col-auto p-0">
					<h3>
						<b>{{ $t('global.title.pension') }}</b>
					</h3>
				</div>
			</div>
			<Field>
				<template slot="title">
					{{ $t('employeePension.voluntaryCompanyContribution') }}
				</template>
				<template slot="comment">
					<Comment v-model="employeePension.voluntaryCompanyContribution.comment" :disabled="disabled" />
				</template>
				<template slot="comp">
					<InputNumber v-model="employeePension.voluntaryCompanyContribution.value" :disabled="disabled" />
					<InputDropdownValuta class="mt-1" v-model="employeePension.voluntaryCompanyContribution.type" :disabled="disabled" :required="true" />
				</template>
			</Field>

			<Field>
				<template slot="title">
					{{ $t('employeePension.voluntaryEmployeeContribution') }}
				</template>
				<template slot="comment">
					<Comment v-model="employeePension.voluntaryEmployeeContribution.comment" :disabled="disabled" />
				</template>
				<template slot="comp">
					<InputNumber v-model="employeePension.voluntaryEmployeeContribution.value" :disabled="disabled" />
					<InputDropdownValuta class="mt-1" v-model="employeePension.voluntaryEmployeeContribution.type" :disabled="disabled" :required="true" />
				</template>
			</Field>

			<Field>
				<template slot="title">
					{{ $t('employeePension.employeeDeposit') }}
				</template>
				<template slot="comment">
					<Comment v-model="employeePension.employeeDeposit.comment" :disabled="disabled" />
				</template>
				<template slot="comp">
					<InputNumber v-model="employeePension.employeeDeposit.value" :disabled="disabled" />
					<InputDropdownValuta class="mt-1" v-model="employeePension.employeeDeposit.type" :disabled="disabled" :required="true" />
				</template>
			</Field>
			<Field>
				<template slot="title">
					{{ $t('employeePension.fromDate') }}
				</template>
				<template slot="comp">
					<InputDate :disabled="disabled" v-model="employeePension.fromDate" :required="pensionHasChanged"> </InputDate>
				</template>
			</Field>
		</template>
	</section>
</template>

<script>
import Field from '@/modules/global/atomComponents/Field.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputDropdownValuta from '@/modules/global/atomComponents/InputDropdownValuta.vue'
import Comment from '@/modules/global/atomComponents/InputComment'
import Supplier from '@/modules/global/components/Supplier'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'

export default {
	name: 'CompanyPension',
	components: {
		InputDate,
		Field,
		InputNumber,
		InputDropdownValuta,
		Comment,
		InputDropdown,
		Supplier,
	},
	props: {
		value: Object,
		pensionIndex: Number,
		disabled: Boolean,
		suppliers: Array,
		standardPension: Object,
	},
	data() {
		return {
			pensionHasChanged: false,
		}
	},
	watch: {
		'employeePension.voluntaryEmployeeContribution.value': function (newVal, oldVal) {
			this.pensionHasChanged = true
		},
		'employeePension.voluntaryCompanyContribution.value': function (newVal, oldVal) {
			this.pensionHasChanged = true
		},
		'employeePension.employeeDeposit.value': function (newVal, oldVal) {
			this.pensionHasChanged = true
		},
	},
	computed: {
		employeePension: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
